body {
	margin: 0;
	font-family: 'Red Hat Display', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffffff;
  padding-bottom: 60px
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.error {
	-webkit-text-fill-color: white;
	-webkit-text-stroke-color: #4c3b92;
	-webkit-text-stroke-width: 1px;

	font-family: "Bold 30px/39px Roboto" !important;
	font-weight: 800 !important;
	position: relative !important;
	font-size: 9em;
	margin: 10px;
	text-align: center;
	background: #fafafa;
}
@keyframes noise-anim-2 {
	0% {
		clip-path: inset(40% 0 61% 0);
	}
	20% {
		clip-path: inset(92% 0 1% 0);
	}
	40% {
		clip-path: inset(43% 0 1% 0);
	}
	60% {
		clip-path: inset(25% 0 58% 0);
	}
	80% {
		clip-path: inset(54% 0 7% 0);
	}
	100% {
		clip-path: inset(58% 0 43% 0);
	}
}
@keyframes noise-anim-1 {
	0% {
		clip-path: inset(60% 0 30% 0);
	}
	20% {
		clip-path: inset(40% 0 70% 0);
	}
	40% {
		clip-path: inset(90% 0 20% 0);
	}
	60% {
		clip-path: inset(54% 0 7% 0);
	}
	80% {
		clip-path: inset(1% 0 68% 0);
	}
	100% {
		clip-path: inset(30% 0 98% 0);
	}
}
.error::before,
.error::after {
	content: attr(data-text);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fafafa;
}
.error::before {
	-webkit-text-fill-color: white;
	-webkit-text-stroke-color: #4c3b92;
	-webkit-text-stroke-width: 1px;

	left: 3px;
	text-shadow: -1px 0 red;

	/* important: opaque background masks the original */
	animation: noise-anim-2 2s infinite linear alternate-reverse;
}
.error::after {
	-webkit-text-fill-color: white;
	-webkit-text-stroke-color: #4c3b92;
	-webkit-text-stroke-width: 1px;

	right: 3px;
	text-shadow: 1px 0 blue;

	/* important: opaque background masks the original */
	animation: noise-anim-1 2s infinite linear alternate-reverse;
}
